
@import '~@/assets/sass/design.sass'

.progressBarContainer
  position: relative
  text-align: center

.circleChart
  transform: rotateZ(-90deg)
  &Background
    stroke: $c-boulder-grey
  &Slide
    animation: dash 2s ease-out forwards
    &.primaryGreen
      stroke: $c-acc-green
    &.primaryBlue
      stroke: $c-acc-blue
    &.primaryRed
      stroke: $c-acc-red

.title
  margin-top: $base-spacing
  text-align: center

.content
  position: absolute
  top: 50%
  right: 0
  left: 0
  margin: 0 auto
  font-size: 16px
  font-weight: bold
  text-align: center
  transform: translateY(-50%)

@keyframes dash
  from
    stroke-dashoffset: 600
